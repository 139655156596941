import React from 'react'
import Layout from '../components/Layout'
import Title from "../components/Title"
import styles from "../css/service.module.css"

const contacto = () => {
    return (
        <div>
            <Layout newLink={true}>
            <section className={styles.services}>
            <Title title="Learn More" />
            
            </section>
            </Layout>
            
        </div>
    )
}
export default contacto